import React, { useEffect, useState } from 'react';
import RightArrow from '../../components/icons/RightArrow';
import logo from "../../assets/images/login/medilogo.png"
import AddIcon from '../../components/icons/AddIcon';
import { getAssessmentList } from '../../api/instructor.rest';
import CustomModal from '../../components/common/CustomModal';
import { getOrgManikins } from '../../api/manikin.rest';
import { getUsersByType } from '../../api/user.rest';
import { getCurrentDateTime } from "../../components/common/utils";
import AssignTestModal from '../../components/common/AssignTestModal';
import AssignmentForm from './AssignmentForm';

const AssignMentList = () => {

    const [assessmentList, setAssessmentList] = useState([])
    const [modalHide, setModalHide] = useState(false)
    const [manikinDetails, setManikinDetails] = useState([])
    const [allUserData, setAllUserData] = useState([]);
    const [modalHide1, setModalHide1] = useState(false)
    const orgId = localStorage.getItem("orgId")
    const [selectedStid,setSelectedStid]=useState([])
    const [formData, setFormData] = useState({
        uid: '',
        assessmentName: "",
        mnkId: "",
        start_date: "",
        due_date: ""
    })
    const [assignDet, setAssignDet] = useState({})

    const getAllusersByType = async () => {
        try {
            const res = await getUsersByType()

            setAllUserData(res.data?.data)
        } catch (error) {
            console.log(error);
        }
    }

    const getManikinListData = async (OrgId) => {
        const res = await getOrgManikins(OrgId)

        setManikinDetails(res.data?.data)
    }

    useEffect(() => {
        getAllusersByType()
        getManikinListData(orgId)
    }, [])

    const modalCallBack=(modal)=>{
        setModalHide1(modal)
    }

    const onClose = () => {
        setModalHide(false)
    }

    const onClose1 = () => {
        setModalHide1(false)
    }

    const getStudentListData = async () => {
        try {
            const res = await getAssessmentList()
            setAssessmentList(res.data?.data)

        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getStudentListData()
    }, [])


    const formatDate = (date) => {
        const fdate = new Date(date);
        const formattedDate = fdate.toLocaleDateString();
        return formattedDate
    }

    const inputHandler = (eve, type) => {
        const txt = eve.target.value;
        if (type === 'assessmentName') {
            setFormData({
                ...formData,
                assessmentName: txt
            });
        }
        if (type === 'mnkId') {
            setFormData({
                ...formData,
                mnkId: txt
            });
        }
        if (type === 'start_date') {
            setFormData({
                ...formData,
                start_date: txt
            });
        }
        if (type === 'due_date') {
            setFormData({
                ...formData,
                due_date: txt
            });
        }
    }

    const handleAssignmentClick = (AssignD) => {
        console.log("asss", AssignD);
        setAssignDet(AssignD)
        setModalHide(true)
    }

    return (
        <div className='w-[100vw-60px] h-[100vh] bg-[var(--bg-color1)] overflow-y-scroll'>
            <div className="z-40 w-max h-max flex items-center p-2 gap-1">
                <div className="manikin-cont w-[40px] h-[40px]">
                    <img src={logo} alt="logo" className='bg-[var(--text-primary-color)] rounded-full' />
                </div>
                <div className="w-max text-xl font-normal text-[#787878]">
                    MedTrain AI
                </div>
            </div>

            <div className='px-4 py-4'>
                <div className='flex justify-end gap-3 items-center' >
                    <div className='font-extrabold'>Assignments</div>
                    <div className='border-[0.1px] border-black flex justify-center items-center' onClick={()=>setModalHide1(true)}>
                        <AddIcon />
                    </div>
                </div>

                <div className=' h-[100%] grid grid-cols-1 sm:grid-cols-2 grid 3xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 gap-y-10 pt-4  place-items-center overflow-y-scroll' >
                    {assessmentList?.map((Assessment, i) => {
                        return (
                            <div className='flex flex-col justify-between border-[0.1px] border-[#CCCCCC] w-[18rem] h-[18rem] p-2 bg-[var(--secondary-color)]'>
                                <div className='w-[100%] h-max flex flex-col gap-2' >
                                    <div className='pb-2'>
                                        <div className='font-semibold text-base'>{Assessment.assessment_name}</div>
                                    </div>
                                    <div>
                                        <div className='font-semibold text-xs'>Manikin Name:</div>
                                        <div className='font-extrabold '>{Assessment.masterDevice?.device_name}</div>
                                    </div>
                                    <div>
                                        <div className='font-semibold text-xs'>Start Date:</div>
                                        <div className='font-extrabold '>
                                            {formatDate(Assessment?.start_date)}
                                        </div>
                                    </div>
                                    <div>
                                        <div className='font-semibold text-xs'>End Date:</div>
                                        <div className='font-extrabold '>
                                            {formatDate(Assessment?.due_date)}
                                        </div>
                                    </div>
                                    <div>
                                        {/* <div>Last Test Score</div>
                                <div>{stdDetails['Last Test Score']}</div> */}
                                    </div>
                                </div>

                                <div className='w-[100%] h-max flex justify-between items-center gap-4'>
                                    <div className='text-[0.9rem] font-semibold'>Assigned to {Assessment?.assessmentMap?.length} students</div>
                                    <div className='cursor-pointer' title='Dashboard' onClick={() => handleAssignmentClick(assessmentList[i])}>
                                        <RightArrow />
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>

                <AssignTestModal heading={"Assignment Details"} handleClose={onClose} open={modalHide}>

                    <div className='text-secondaryColor font-bold text-xl py-4 px-3'>Assignment Details</div>

                    <div className='flex w-100% justify-between px-3' >
                        <div>{assignDet?.assessment_name}</div>
                        <div className='flex gap-2'>
                            <div>Assigned by:</div>
                        <div> {assignDet?.userMaster?.Unm}</div>
                        </div>
                    </div>
                
                    <div className=' h-max grid grid-cols-1 sm:grid-cols-2 grid 3xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 gap-y-10 pt-4  place-items-center overflow-y-scroll gap-2 px-3' >
                    
                        {assignDet?.assessmentMap?.map((details, i) => {
                            return (
                                <div className='flex flex-col justify-between border-[0.1px] border-[#CCCCCC] w-[10rem] h-[7rem] p-2 bg-[var(--secondary-color)]'>
                                    <div className='w-[100%] h-max flex flex-col gap-2' >
                                        <div className='pb-2'>
                                            <div className='font-semibold text-base'>{details?.userMaster?.Unm}</div>
                                        </div>
                                        <div className='flex'>
                                            <div className=' font-medium capitalize text-xs'>{details?.status==="stop"?"completed":details?.status}</div>
                                        </div>
                                        {details?.status==="stop" && details?.test_end &&
                                            <div>
                                            {/* <div className='font-normal text-xs'>Completed Date:</div> */}
                                            <div className='text-xs'>
                                                { getCurrentDateTime(details?.test_end)}
                                            </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </AssignTestModal>

                <AssignTestModal handleClose={onClose1} open={modalHide1}>
                <div className='p-4 font-semibold'>Assign a test</div>
                    <AssignmentForm modalCallBack={modalCallBack} getStudentListData={getStudentListData} allUserData={allUserData} selectedStid={selectedStid} modalHide1={modalHide1}/>
                </AssignTestModal>

            </div>
        </div>
    );
}

export default AssignMentList;
