import React, { useEffect, useState } from 'react';
import { Allotment } from "allotment";
import "allotment/dist/style.css";
// import logo from "../../assets/images/login/medilogo.png"
import logo from "../../assets/images/login/logo_new.png";
import AssignedTestData from './AssignedTestData';
import { getAssignedTest } from '../../api/instructor.rest';
import { commandMQTTWithAssignment } from '../../api/mqtt.rest';
import { useNavigate } from 'react-router-dom';
import PlayBtn from '../../components/icons/PlayBtn';
import Stop from '../../components/icons/Stop';
import PauseBtn from '../../components/icons/PauseBtn';
import { useTimer } from "react-use-precision-timer";

const animDelay = 1;
const AssignedtTest = () => {
  const userType=localStorage.getItem("userType") 
  const [assigment, setAssignMent] = useState([])
  const [assigmentData, setAssignMentData] = useState(null)
  const [isRunning, setIsRunning] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0);
  const navigate = useNavigate();
  const timers = useTimer({ delay: 100 });

  const getAssignedTestData = async () => {
    try {
      if (userType=== "user") {
        const res = await getAssignedTest("pending")
        setAssignMent(res?.data?.data)

        
      }
    } catch (error) {
      console.log("eeeeeee", error);
    }
  }

  // useEffect(() => {
  //   setAssignMentData(assigment[0])
  // }, [assigment])

  useEffect(() => {
    getAssignedTestData()
  }, [])

  //   const formatDate=(date)=>{
  //     const fdate = new Date(date);
  //     const formattedDate = fdate.toLocaleDateString();
  //     return formattedDate
  // }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  }


  const commandMQTTAPI = (action) => {
    return new Promise(async (resolve, reject) => {
      try {
        await commandMQTTWithAssignment(action, assigment[0]?.mnk_id, assigment[0]?.id);
        resolve("started");
      } catch (error) {
        reject(error);
      }
    });

  };

  const handleAssessmentTest = () => {
    if (!isRunning) {
      commandMQTTAPI("START")
      timers.start()
    } else {
      commandMQTTAPI("STOP")
      timers.stop()
      getAssignedTestData()
    }
    setIsRunning(!isRunning);
  }

  const handleStartAssessment=()=>{
    if (!isRunning) {
    commandMQTTAPI("START")
      timers.start()
    }
      setIsRunning(!isRunning);
  }


  const handleStopAssessment=()=>{
    if (isRunning) {
      commandMQTTAPI("STOP")
      timers.stop()
      getAssignedTestData()
    }
    setIsRunning(!isRunning);
  }

  useEffect(() => {
    let interval;

    if (isRunning) {
      interval = setInterval(() => {
        setElapsedTime((prevElapsedTime) => prevElapsedTime + 1);
      }, animDelay);
    }

    return () => {
      setElapsedTime(0);
      clearInterval(interval);
    };
  }, [isRunning]);
  
  const formatTime = (time) => {
    // const minutes = Math.floor(time / 60000);
    const seconds = parseInt(((time / 1000)));
    const ms = (time % 1000);

    return ` ${seconds.toString().padStart(2, "0")} sec. ${ms
      .toString()
      .padStart(3, "0")} ms.`;
  };

  function getDateDifference(startDate, dueDate) {
    const start = new Date(startDate);
    const end = new Date(dueDate);
    
    // Calculate the difference in milliseconds
    const difference = Math.abs(end - start);
    
    // Convert milliseconds to days
    const days = Math.ceil(difference / (1000 * 60 * 60 * 24));
    
    // Format days to have leading zero if needed
    const formattedDays = String(days);
    
    return formattedDays;
  }

  const handleNavigateClick=()=>{
    navigate('/assignmentList')
  }

  return (
    <>
      <div style={{ width: "100%", height: "100vh" }} className="flex">
        <Allotment className="h-100 play-and-learn-container">
          <Allotment.Pane className="left-pane" minSize={300}>
            <Allotment
              vertical
              className="h-100 left-pane-allotment"
              minSize={200}
            >
              <Allotment.Pane className="top-left-pane p-0">
                <div className="item video-player  h-100  flex-column gap-2 manikin-svg-container">


                  <div className='flex w-[100%] h-[100%] justify-between '>
                    <div className="z-40 w-max h-max flex  p-2 gap-1">
                    <a href="http://medtrain.ai" target="_medtrainai">
                    <div className="manikin-cont w-[100px] h-[20px]">
                            <img
                              src={logo}
                              alt="logo"
                              className=""
                            />
                          </div>
                          </a>
                      {/* <div className="w-max text-sm font-normal text-[#787878]">
                        MedTrain AI
                      </div> */}
                      
                    </div>
                    {assigment[0]?.id ? <>
                      {isRunning? <>
                      <div className='flex flex-col justify-center items-end w-max h-100'>
                        <div className="flex justify-center items-center  text-gray-500 text-[2.5rem] font-semibold pt-2 font-normal"> {formatTime(timers.getElapsedStartedTime())}</div>
                      <div className='flex w-max justify-end h-max bg-[#D0D0D0] text-black text-[0.8rem] py-2'>Test in Progress...</div>
                      </div>
                    </>:
                    <div className='flex flex-col justify-center items-center'>
                      <div onClick={handleStartAssessment}><PlayBtn/></div>
                    <div className="  text-black pt-2 pb-3 font-normal"> Start the test</div>
                    </div>
                    }
                    
                    </>: 
                      <>
                        <div className="flex justify-center items-center  text-gray-500 pt-2 pb-3 font-normal"> No test assigned</div>
                      </>}
                    <div className="relative w-[250px] h-[100%] bg-white p-2 z-50 bg-red-500">
                      <div className="text-gray-500 pt-2 pb-3 font-normal">Assigned Test</div>
                      <div className='text-black font-semibold underline decoration-sky-500 border-b-[0.1px] border-sky-500'>{assigment[0]?.masterDevice?.device_name}</div>
                      
                      {assigment[0]?.id ?
                      <div className=' h-max flex flex-col gap-6'>
                      <div className='text-black font-normal pt-4 text-sm flex flex-col gap-4 '>
                        <div >
                          <div>Assigned by:</div>
                          <div>{assigment[0]?.userMaster?.Unm}</div>
                        </div>

                        <div>
                          <div>Date | Time:</div>
                          <div>{formatDate(assigment[0]?.start_date)}</div>
                        </div>

                        <div>
                          <div>Due in:</div>
                          <div>{getDateDifference(assigment[0]?.start_date,assigment[0]?.due_date)}&nbsp;Days</div>
                        </div>

                        <div className='w-max py-[0.05rem] px-[0.3rem]  text-[#fff] hover:cursor-pointer' >
                        {!isRunning ? <div className='bg-[var(--primary-color)] px-3 py-1.5' onClick={handleStartAssessment}>Start test</div>: <div className='flex gap-4 justify-center items-center'>
                         <div className='flex w-max justify-end h-max bg-[#D0D0D0] text-black text-[0.8rem] py-2'>Test in Progress...</div>
                         <div onClick={handleStopAssessment}><Stop color="#C00000"/></div>
                         </div>}
                        </div>
                      </div>
                    
                      </div>
                      : 
                      <div className=" flex justify-center items-center h-100 text-black text-[0.8rem] pt-2 pb-3 font-normal">
                        No test assigned
                      </div>
                      }
                      <div className='text-[0.8rem] absolute bottom-[4rem] text-blue-700 underline border-b-[0.1px] border-blue-700 cursor-pointer' onClick={handleNavigateClick}>View all test sessions</div>
                    </div>

                  </div>
                  {/* device select dropdown */}
                </div>

              </Allotment.Pane>




              {/* bottom section */}

              <Allotment.Pane
                className="bottom-left-pane pt-0 border-t-2 border-gray-200"
                preferredSize={"30%"}
                minSize={100}
              >
                {/* <AssignedTestData isRunning={isRunning}/> */}

              </Allotment.Pane>

              {/* bottom section */}
            </Allotment>
          </Allotment.Pane>

        </Allotment>


      </div>

    </>
  );
}

export default AssignedtTest;
