import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { BiChip, BiSearch } from 'react-icons/bi';
import { FaBars, FaUserFriends } from 'react-icons/fa';
import { FaMicrochip } from 'react-icons/fa6';
import { HiOutlineComputerDesktop } from 'react-icons/hi2';
import { MdBrowserUpdated, MdSensors, MdWifiProtectedSetup } from 'react-icons/md';
import { RiPlaystationLine, RiUserSettingsLine, RiWirelessChargingFill } from 'react-icons/ri';
import { TbHelpHexagon } from 'react-icons/tb';
import { Link, NavLink, Outlet, useLocation } from 'react-router-dom';
import TopNavbar from '../top-navbar';
import logo from "../../assets/images/login/medilogo.png"
import WatchLearn from '../icons/WatchLearn';
import PlayLearn from '../icons/PlayLearn';
import Manikin from '../icons/Manikin';
import HelpCenter from '../icons/HelpCenter';
import SessionRecording from '../icons/SessionRecording';
import Chat from '../icons/Chat';
import Notification from '../icons/Notification';
import DarkMode from '../icons/DarkMode';
import More from '../icons/More'
import { useNavigate } from 'react-router-dom';
import { GrLogout } from "react-icons/gr";
import AssessmentIcon from '../icons/AssessmentIcon';
import AssignmentInd from '../icons/AssignmentInd';
import AssignmentIcon from '../icons/AssignmentIcon';
import Box from "@mui/material/Box"; 
import Button from "@mui/material/Button"; 
import Tooltip from "@mui/material/Tooltip";
import TooltipUi from './TooltipUi';
import { Switch } from "@mui/material"
import { MdLightMode } from "react-icons/md";
import { MdOutlineErrorOutline } from "react-icons/md";
import ErrorIcon from '../icons/ErrorIcon';





function AdminContainer({ children,toogleSwitch }) {
    const tooltipUName=localStorage.getItem("userNm")
    const userName=localStorage.getItem("userNm").slice(0,2)
    const userType=localStorage.getItem("userType")

    const user_routes = [
        {
            path: '/playAndLearn',
            name: `${tooltipUName}`,
            icon:`${userName}`
        },
        {
            path: '/playAndLearn',
            name: 'Play & Learn',
            
            icon: <WatchLearn/>
        },
        {
            path: '/watchLearn',
            name: 'Watch & Learn',
            icon: <PlayLearn/>
        },
    
        {
            path: '/session',
            name: 'Sessions',
            icon: <SessionRecording/>
        },
        // {
        //     path: '/manikin',
        //     name: 'Manikins',
        //     icon: <Manikin/>
        // },
        {
            path: '/assignedtest',
            name: 'Assessment',
            icon: <AssessmentIcon/>
        },
        // {
        //     path: '/chat',
        //     name: 'Chat',
        //     icon: <Chat/>
        // },
        {
            path: '/getHelp',
            name: 'Get Help',
            icon: <HelpCenter/>
        },
    ]
    
    const Instructor_routes = [
        {
            path: '/student',
            name: `${tooltipUName}`,
            icon:`${userName}`
        },
        {
            path: '/student',
            name: 'Student',
            icon: <AssignmentInd/>
        },
        {
            path: '/assignment',
            name: 'Assignment',
            icon: <AssignmentIcon/>
        },
        // {
        //     path: '/playAndLearn',
        //     name: 'Play & Learn',
            
        //     icon: <WatchLearn/>
        // },
        // {
        //     path: '/watchLearn',
        //     name: 'Watch Learn',
        //     icon: <PlayLearn/>
        // },
    
        // {
        //     path:"session",
        //     name: 'Session',
        //     icon: <SessionRecording/>
        // },
        {
            path: '/manikin',
            name: 'Manikin',
            icon: <Manikin/>
        },
        // {
        //     path:"chat",
        //     name: 'Chat',
        //     icon: <Chat/>
        // },
        {
            path: '/getHelp',
            name: 'Get Help',
            icon: <HelpCenter/>
        },
    ]
    
    const  orgAdmin_routes = [
        {
            path: 'instructor',
            name: `${tooltipUName}`,
            icon:`${userName}`
        },
        {
            path: 'instructor',
            name: 'Instructor',
            icon:<Manikin/>
        },
        {
            path: 'student',
            name: 'Student',
            icon:<AssignmentInd/>
        },
        {
            path: 'manikin',
            name: 'Manikin',
            icon:<AssignmentIcon/>
        },
    ]
    
    const root_routes=[ 
        {
            path: '/manikin',
            name: `${tooltipUName}`,
            icon:`${userName}`
        },
        {
            path: '/manikin',
            name: 'Manikin',
            icon:<Manikin/>
        },
        {
            path: '/organization',
            name: 'Organization',
            icon:<AssignmentIcon/>
        },
        {
            path: '/errorcode',
            name: 'ErrorCode',
            icon: <ErrorIcon/>
        },
        
        // {
        //     path: '/orgadminlist',
        //     name: 'OrgAdminList',
        //     icon:<AssignmentInd/>
        // },
    ]



    
    const location = useLocation();
    const [toggleDarkMode, setToggleDarkMode] = useState(true);
    const user_type = localStorage.getItem('userType');
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    
    const toggleDarkTheme = () => {
        setToggleDarkMode(!toggleDarkMode);
       
      };

    const toggle = () => setIsOpen(!isOpen);
    const inputAnimation = {
        hidden: {
            width: 0,
            padding: 0,
            opacity: 0,
        },
        show: {
            width: "140px",
            padding: "5px 15px",
            opacity: 1,
            transition: {
                duration: 0.2
            }
        }
    }
    const showAnimation = {
        hidden: {
            width: 0,
            opacity: 0,
            transition: {
                duration: 0.5
            }
        },
        show: {
            width: "auto",
            opacity: 1,
            transition: {
                duration: 0.2
            }
        }
    }

    const logOut = () => {
            localStorage.removeItem("token");
            localStorage.removeItem("userType");
            navigate("/login");
            window.location.reload();
    }

    useEffect(()=>{
        toogleSwitch(toggleDarkMode)
    },[toogleSwitch,toggleDarkMode])

    /*
    * Init 
    * Function Call
    */
    const routeData = () => {
        if (user_type === 'root') {
            return root_routes;
        }
        if (user_type === 'user') {
            return user_routes;
        }
        if (user_type==="instructor") {
            return Instructor_routes;
        }
        if (user_type==="admin") {
            return orgAdmin_routes;
        }
    }




    //   /*
    //   * Init 
    //   * Function Call
    //   */
    //   useEffect(() => {
    //     // routeData();
    //   }, []);
    return (
        <div className='main-container'>
            
            {/* <main>{children}</main> */}
            <main>
                {/* <TopNavbar /> */}
                <div style={{ height: "calc(100% - 60px)" }} className={`container-fluid ${location?.pathname === "/playAndLearn" ? "p-0" : "px-0"}`}> <Outlet /> </div>
            </main>
            <motion.div animate={{
                width: isOpen ? '210px' : "4rem",
                transition: {
                    duration: 0.5,
                    type: 'spring',
                    damping: 11,
                }
            }} className='sidebar'>
                {/* <div className="top_section"> */}
                    {/* {isOpen && <motion.h1 */}
                        {/* initial='hidden'
                        animate='show'
                        exit='hidden'
                        variants={showAnimation}
                        className="">
                         <div className='flex gap-2 text-base font-semibold text-[var(--text-primary-color)]'> 
                         <div className='bg-red-600 h-max'> */}
                            {/* <img src={logo} alt="logo" width='15px' height="15px"  /> */}
                         {/* </div>MedTrain AI</div> */}
                    {/* </motion.h1>} */}
                    {/* <div className="bars">
                        <FaBars onClick={toggle} />
                    </div> */}
                {/* </div> */}
                {/* <div className="search">
                    <div className="search_icon">
                        <BiSearch />
                    </div>
                    <AnimatePresence>
                        {isOpen &&
                            <motion.input
                                initial='hidden'
                                animate='show'
                                exit='hidden'
                                variants={inputAnimation}
                                placeholder='Search...'
                            />
                        }
                    </AnimatePresence>
                </div> */}
                <section className='routes h-[100%] flex flex-col justify-between'>
                    <div className='h-max'>
                    {
                        routeData()?.map((route,i) => {
                            if (route?.type === "parent") {
                                return <div key={i} className='h-max'>
                                    <Link key={route?.name} aria-disabled disabled className={`link ${isOpen ? "" : "d-none"}`}>
                                        <div style={{ width: "16px" }} className="icon text-center" data-bs-toggle="tooltip" data-bs-placement="right" title={route.name}>{route?.name?.charAt(0)?.toUpperCase()}</div>
                                        <AnimatePresence>
                                            {isOpen && <motion.div
                                                initial='hidden'
                                                animate='show'
                                                exit='hidden'
                                                variants={showAnimation}
                                                className="link_text">{route?.name}
                                            </motion.div>}
                                        </AnimatePresence>
                                    </Link>
                                    <div className={isOpen ? "ps-3 " : ""}>
                                        {route?.children?.map((elem) => (
                                            <NavLink
                                                activeclassname="active"
                                                to={elem.path}
                                                key={elem.name}
                                                className='link'
                                            >
                                                <div className="icon" data-bs-toggle="tooltip" data-bs-placement="right" title={elem.name}>{elem.icon }</div>
                                                <AnimatePresence>
                                                    {isOpen && <motion.div
                                                        initial='hidden'
                                                        animate='show'
                                                        exit='hidden'
                                                        variants={showAnimation}
                                                        className="link_text">{elem.name}
                                                    </motion.div>}
                                                </AnimatePresence>
                                            </NavLink>
                                        ))}
                                            
                                    </div>
                                </div>
                            } else {
                                return <Box  
                                justifyContent="center"> 
                                <Tooltip className='uppercase' title={route?.name}
                                    placement="left" arrow> 
                                    <NavLink
                                activeclassname="active"
                                to={route.path && route.path}
                                key={route.name}
                                className={`link relative uppercase border-b-[0.1px] border-[#c9c8c84a] ${(userType === "user" && i===0)?'bg-[#7030A0]':(userType === "instructor" && i===0) ?'bg-[#5F8944]':(userType === "admin" && i===0)?'bg-green-600':(userType === "root" && i===0)?'bg-[#C00000]':""}` }
                            >
                                <div className="icon uppercase text-[#fff]" data-bs-toggle="tooltip" data-bs-placement="right" >{route.icon}<span className={`absolute left-0 bottom-0 ${route.icon==="MC" || route.icon==="TI" ?"block ":"hidden"} hover:"block"`} ><More color="#fff"/></span></div>
                                {/* <AnimatePresence>
                                    {isOpen && <motion.div
                                        initial='hidden'
                                        animate='show'
                                        exit='hidden'
                                        variants={showAnimation}
                                        className="link_text">{route.name}
                                    </motion.div>}
                                </AnimatePresence> */}
                            </NavLink>
                                </Tooltip> 
                            </Box> 
                      }
                        }
                        )
                    }
                    </div>

                    <div className=' flex flex-col'>
                        <div className='w-[100%] h-[41px] flex justify-center items-center    hover:cursor-pointer hover:fill-[red]'>
                        {/* <TooltipUi name="Notifications" icons={<Notification/>} width="max" placement="left"/>   */}
                        </div>
                        <div  className='w-[100%] h-[41px] flex justify-center items-center  hover:text-red-700  hover:cursor-pointer hover:fill-[red]'
                        //  onClick={toggleDarkTheme}
                         >
                            {/* {
                                !toggleDarkMode? */}
                                {/* <TooltipUi name='Darkmode' icons={<DarkMode/>} width="max" placement="left"/> */}
                                  {/* : */}
                                {/* <TooltipUi name='Lightmode' icons={<MdLightMode className='text-black'/>} width="max" placement="left"/>  */}
                            {/* } */}
                          
                            </div>
                            {/* <Switch checked={toggleDarkMode} onChange={toggleDarkTheme} /> */}

                        <div onClick={logOut} className='w-[100%] h-[41px] flex justify-center items-center  hover:text-red-700  hover:cursor-pointer '>
                     <TooltipUi name='Logout' icons={<GrLogout  className='text-lg font-normal text-black'/>} width='max' placement="left"/>  
                        </div>
                    </div>
                </section>
            </motion.div>
        </div>
    )
}

export default AdminContainer;