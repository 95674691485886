import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import styles from "./sysadminlogin.module.scss";
import med from "../../assets/images/login/med.png";
import airflowManikinImg from "../../assets/images/login/slide.png";
import operation from "../../assets/images/login/slide1.jpg";
import ambulance from "../../assets/images/login/slide2.jpg";
// import logo from "../../assets/images/login/medilogo.png"
import logo from "../../assets/images/login/logo_new.png";
import "./slider.css";

function AuthContainer() {
  let slideIndex = 0;
  const showSlide = () => {
    let allSlides = document.getElementsByClassName("slide");
    let slideLen = allSlides.length;
    for (let i = 0; i < slideLen; i++) {
      allSlides[i].style.display = "none";
    }
    slideIndex++;
    if (slideIndex > allSlides.length) {
      slideIndex = 1;
    }

    allSlides[slideIndex - 1].style.display = "block";
    const runinLoop = setTimeout(showSlide, 4000); // Change image every 2 seconds
    console.log(runinLoop)
    return runinLoop;
  };
  // useEffect(() => {
  //   let getTimeOutId = showSlide();
  //   return function () {
  //     clearTimeout(getTimeOutId);
  //   };
  // });
  useEffect(() => {
    if(window.simpleslider){
      // console.log(window.simpleslider.getSlider);
      let imageSlider = window.simpleslider.getSlider({
        container: document.getElementById('_image-slider'),
        prop: 'left',
        init: 612,
        show: 0,
        end: -612,
        unit: 'px',
        delay: 10,
        duration: 1
      })
    }
    // let imageSlider = simpleSlider.getSlider({})
  },[])
  return (
    <div className={styles.auth_cont}>
      <div className={styles.auth_subcontainer}>
        <div className="img-slider">
          {/* text */}
          <div className={styles.tagline_cont}>
            <div className={styles.top_box}>
              <p className={styles.com_title}>DRISH</p>
              <div className={styles.box1}>for</div>
            </div>
            <div className={styles.box2}>NextGen HealthCare</div>
            <span className={styles.logotext}>Training</span>
          </div>
          {/* <div className="slider-container">
            <div className="slide fade">
              <img src={ambulance} alt="med_bg" />
            </div>
            <div className="slide fade">
              <img src={airflowManikinImg} alt="med_bg" />
            </div>
            <div className="slide fade">
              <img src={med} alt="med_bg" />
            </div>

            <div className="slide fade">
              <img src={operation} alt="operation_bg" />
            </div>
          </div> */}
          <div id="_image-slider">
          <img src={ambulance} alt="med_bg" />
          <img src={airflowManikinImg} alt="med_bg" />
          <img src={med} alt="med_bg" />
          <img src={operation} alt="operation_bg" />
          </div>
        </div>
        <div className={styles.forms_parentcontainer}>
          <div className={styles.forms_parentubcontainer}>
            <div className={styles.company_title}>
              {" "}
              <a href="http://medtrain.ai" target="_medtrainai">
                <div>
                  <img
                    src={logo}
                    alt="logo"
                    width="150px"
                    height="250px"
                    className={styles.logo_img}
                  />
                </div>
              </a>
              {/* <span className="text-4xl font-bold">MedTrain AI</span> */}
            </div>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AuthContainer;
