import React, { useEffect, useState } from 'react';
import logo from "../../assets/images/login/medilogo.png"
import AddIcon from '../../components/icons/AddIcon';
import CustomModal from '../../components/common/CustomModal';
import AssignmentAdd from '../../components/icons/AssignmentAdd';
import RightArrow from '../../components/icons/RightArrow';
import { getUsersByType } from '../../api/user.rest';
import AssignTestModal from '../../components/common/AssignTestModal';
import { newUserCreate } from '../../api/user.rest';
import { toast } from "react-toastify"
import TooltipUi from '../../components/common/TooltipUi';
import { getUsersByTypeAll } from '../../api/user.rest';

const InstructorList = () => {
    const [modalHide, setModalHide] = useState(false)
    const [allUserData, setAllUserData] = useState([]);
    const orgId = localStorage.getItem("orgId")
    const [stdName, setStdName] = useState("")
    const [selectedStid, setSelectedStid] = useState([])
    const [modalHide1, setModalHide1] = useState(false)
    const [uNameErrMsg, setUNameErrMsg] = useState("")
    const [unmErrMsg,setUnmErrMsg]=useState("")
    const [pswErrMsg, setPswErrMsg] = useState("")
    const [addErrMsg, setAdderrMsg] = useState("")
    const [mobErrMsg, setMobErrMsg] = useState("")
    const [userName, setUserName] = useState("")
    const [unm, setUnm] = useState("")
    const [password, setPassword] = useState("")
    const [address, setAdderss] = useState("")
    const [mobNumber, setMobNumber] = useState("")

    const getAllusersByTypeAll = async () => {
        try {
            const res = await getUsersByTypeAll('ut_cPu7hZ8IU')
            setAllUserData(res.data?.data)
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getAllusersByTypeAll()
    }, [])

    const onClose = () => {
        setModalHide(false)
    }

    //   const formatDate=(date)=>{
    //     const fdate = new Date(date);
    //     const formattedDate = fdate.toLocaleDateString();
    //     return formattedDate
    // }

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'short', day: '2-digit' };
        return new Date(dateString).toLocaleDateString('en-GB', options);
    };

    const userValid = () => {
        let checked = true;

        if (userName === "") {
            checked = false;
            setUNameErrMsg("enter userName")
            console.log("checked1", checked);
        } else {
            setUNameErrMsg("")
        }
        if (unm === "") {
            checked = false;
            setUnmErrMsg("enter userName")
        } else {
            setUnmErrMsg("")
        }
        if (password === "") {
            checked = false;
            setPswErrMsg("enter password")
        } else {
            setPswErrMsg("")
        }
        if (address === "") {
            checked = false;
            setAdderrMsg("enter address")
        } else {
            setAdderrMsg("")
        }
        if (mobNumber === "") {
            checked = false;
            setMobErrMsg("enter mobile number")
        } else {
            setMobErrMsg("")
        }
        return checked;
    }

    const createUser = async () => {
        const data = {
            "utype_id": "ut_cPu7hZ8IU",
            "name": userName,
            "Unm": unm,
            "password": password,
            "Uaddress": address,
            'umob': mobNumber,
            'org_id': orgId,
            'uorg_idcard': ""
        }
        try {
            const res = await newUserCreate(data)
            console.log("sss", res?.status);
            if (res?.status === 200) {
                toast.success("new instructor created")
                getAllusersByTypeAll()
                setModalHide(false)
            }

        } catch (err) {
            if (err?.response?.status === 400) {
                toast.error(err?.response?.data?.data)
            }
        }
    }




    const onUserSubmit = () => {

        if (userValid()) {
            createUser()

            setUserName("")
            setUnm("")
            setPassword("")
            setAdderss("")
            setMobNumber("")
        }
        setModalHide(true)

    }

    useEffect(() => {
        if (modalHide === false) {
            setUserName("")
            setUnm("")
            setPassword("")
            setAdderss("")
            setMobNumber("")
        }
    }, [modalHide])

    const modalCallBack = (modal) => {
        setModalHide1(modal)
    }

    useEffect(() => {
        modalCallBack()
    }, [])

    const handleAddStudentClick = () => {
        setModalHide(true)
    }

    return (
        <div className='w-[100vw-60px] h-[100vh] bg-[var(--bg-color1)] overflow-y-scroll' >
            <div className="z-40 w-max h-max flex items-center p-2 gap-1">
                <div className="manikin-cont w-[40px] h-[40px]">
                    <img src={logo} alt="logo" className='bg-[var(--text-primary-color)] rounded-full' />
                </div>
                <div className="w-max text-xl font-normal text-[#787878]">
                    MedTrain AI
                </div>
            </div>

            <div className='px-4 pt-4'>
                <div className='flex justify-end gap-3 items-center' >
                    <div className='font-extrabold'>Create Instructor</div>
                    <div className='border-[0.1px] border-black flex justify-center items-center' onClick={handleAddStudentClick}>
                        <AddIcon />
                    </div>
                </div>


                <AssignTestModal heading={"Create Instructor"} handleClose={onClose} open={modalHide}>
                    <div className='p-4 font-extrabold'>Create Instructor</div>
                    <form className='flex w-[500px] h-max flex-col gap-4 px-4 py-4'>
                        <div className='flex flex-col gap-4'>
                            <div className='flex flex-col justify-start'>
                                <div className='flex flex-col justify-start gap-2'>
                                    <label htmlFor="userName" className='font-medium text-[0.8rem]'>Name</label>
                                    <input type="text" id="" placeholder='Enter Name' className='border-b-[0.1px] focus:outline-none font-medium px-2'
                                        onChange={(e) => setUserName(e.target.value)}
                                        value={userName}
                                        name="userName"
                                    />
                                </div>
                                <div className='text-[0.8rem] text-red-500'>{userName === "" ? uNameErrMsg : ""}</div>
                            </div>

                            <div className='flex flex-col justify-start'>
                                <div className='flex flex-col justify-start gap-2'>
                                    <label htmlFor="unm" className='font-medium text-[0.8rem]'>UserName</label>
                                    <input type="text" id="unm" placeholder='Enter UserName' className='border-b-[0.1px] focus:outline-none font-medium px-2'
                                        onChange={(e) => setUnm(e.target.value)}
                                        value={unm}
                                        name="unm"
                                    />
                                </div>
                                <div className='text-[0.8rem] text-red-500'>{unm === "" ? unmErrMsg : ""}</div>
                            </div>

                            <div className='flex flex-col justify-start'>
                                <div className='flex flex-col justify-start gap-2'>
                                    <label htmlFor="password" className='font-medium text-[0.8rem]'>Password</label>
                                    <input type="text" id="" placeholder='Enter Password' className='border-b-[0.1px] focus:outline-none font-medium px-2'
                                        onChange={(e) => setPassword(e.target.value)}
                                        value={password}
                                        name="password"
                                    />
                                </div>
                                <div className='text-[0.8rem] text-red-500'>{password === "" ? pswErrMsg : ""}</div>
                            </div>

                            <div className='flex flex-col justify-start'>
                                <div className='flex flex-col justify-start gap-2'>
                                    <label htmlFor="address" className='font-medium text-[0.8rem]'>Address</label>
                                    <textarea id="" placeholder='Enter Your Address' className='border-[0.1px] focus:outline-none font-medium p-2'
                                        onChange={(e) => setAdderss(e.target.value)}
                                        value={address}
                                        name="address"
                                    ></textarea>
                                </div>
                                <div className='text-[0.8rem] text-red-500'>{address === "" ? addErrMsg : ""}</div>
                            </div>

                            <div className='flex flex-col justify-start'>
                                <div className='flex flex-col justify-start gap-2'>
                                    <label htmlFor="mobNumber" className='font-medium text-[0.8rem]'>Mobile Number</label>
                                    <input type="number" id="" placeholder='Enter Mobile number' className='border-b-[0.1px] focus:outline-none font-medium px-2'
                                        onChange={(e) => setMobNumber(e.target.value)}
                                        value={mobNumber}
                                        name="mobNumber"
                                    />
                                </div>
                                <div className='text-[0.8rem] text-red-500'>{mobNumber === "" ? mobErrMsg : ""}</div>
                            </div>

                        </div>
                        <div className='flex justify-end gap-4 pt-4'>
                            <div className='hover:cursor-pointer  font-medium' onClick={() => setModalHide(false)}>Cancel</div>
                            <div className='w-max py-[0.05rem] px-[0.5rem] bg-[var(--primary-color)] text-[#fff] hover:cursor-pointer font-medium' onClick={onUserSubmit}>
                                Submit
                            </div>
                        </div>

                    </form>
                </AssignTestModal>

                <div className=' h-[100%] grid grid-cols-1 sm:grid-cols-2 grid 3xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 gap-y-10 py-4  place-items-center overflow-y-scroll' >
                    {allUserData?.map((stdDetails, i) => {
                        return (
                            <div className='flex flex-col justify-between border-[0.1px] border-[#CCCCCC] w-[18rem] h-[17rem] p-2 bg-[var(--secondary-color)]'>
                                <div className='w-[100%] h-max flex flex-col gap-2' >
                                    <div className='pb-2'>
                                        <div className='font-semibold text-base'>{stdDetails.name}</div>
                                    </div>
                                    <div>
                                        <div className='font-semibold text-xs'>Status:</div>
                                        <div className='font-extrabold '>
                                            {stdDetails?.ustatus === true ? <span className='text-green-600'>Active</span> : <span className='text-red-600'>InActive</span>}
                                        </div>
                                    </div>
                                    <div>
                                        <div className='font-semibold text-xs'>Address:</div>
                                        <div className='font-extrabold '>{stdDetails?.Uaddress}</div>
                                    </div>
                                    <div>
                                        <div className='font-semibold text-xs'>Mobile No:</div>
                                        <div className='font-extrabold '>
                                            {stdDetails?.umob}
                                        </div>
                                    </div>
                                    <div>
                                        {/* <div>Last Test Score</div>
                                <div>{stdDetails['Last Test Score']}</div> */}
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>

            </div>
        </div>
    );
}

export default InstructorList;
