import React, { useEffect, useState } from 'react';
import logo from "../../assets/images/login/medilogo.png"
import AddIcon from '../icons/AddIcon';
import CustomModal from '../common/CustomModal';
import AssignmentAdd from '../icons/AssignmentAdd';
import RightArrow from '../icons/RightArrow';
import { getUsersByType } from '../../api/user.rest';
import AssignTestModal from '../common/AssignTestModal';
import { toast } from "react-toastify"
import TooltipUi from '../common/TooltipUi';
import { getOrganizations } from '../../api/org.rest';
import { createOrganization } from '../../api/org.rest';
import { Link, useNavigate } from 'react-router-dom';
import { IoSettings } from "react-icons/io5";
import { updateOrganization } from '../../api/org.rest';
import { newUserCreate } from '../../api/user.rest';


const Organization = () => {
    const currentDate = new Date().toISOString().split('T')[0];
    const navigate = useNavigate();
    const [modalHide, setModalHide] = useState(false)
    const [modalHide1, setModalHide1] = useState(false)
    const [modalHide2, setModalHide2] = useState(false)
    const [orgData, setOrgData] = useState([]);
    const orgId = localStorage.getItem("orgId")
    const [orgNameErrMsg1, setOrgNameErrMsg1] = useState("")
    const [orgAddressErrMsg1, setorgAddressErrMsg1] = useState("")
    const [orgTypeErrMsg1, setorgTypeErrMsg1] = useState("")
    const [orgRegDateErrMsg1, setorgRegDateErrMsg1] = useState("")
    const [orgName, setOrgName] = useState("")
    const [orgAddress, setOrgAddress] = useState("")
    const [orgType, setOrgType] = useState("")
    const [orgRegDate, setOrgRegDate] = useState(currentDate)
    const [EditOrgId, setEditOrgId] = useState("")
    const [updateOrganization1, setUpdateOrganization1] = useState(
        {
            org_nm: "",
            org_add: "",
            org_type: "",
            org_regdate: ""
        }
    )

    const [uNameErrMsg, setUNameErrMsg] = useState("")
    const [unmErrMsg,setUnmErrMsg]=useState("")
    const [pswErrMsg, setPswErrMsg] = useState("")
    const [addErrMsg, setAdderrMsg] = useState("")
    const [mobErrMsg, setMobErrMsg] = useState("")
    const [userName, setUserName] = useState("")
    const [unm, setUnm] = useState("")
    const [password, setPassword] = useState("")
    const [address, setAdderss] = useState("")
    const [mobNumber, setMobNumber] = useState("")
    const [adminOrgId, setAdminOrgId] = useState("")



    const getAllOrg = async () => {
        await getOrganizations()
            .then((resp) => {
                setOrgData(resp?.data?.data);
            })
            .catch((e) => {
                console.error(e);
            })
    };

    useEffect(() => {
        getAllOrg()
    }, [])

    const onClose = () => {
        setModalHide(false)
    }

    const onClose1 = () => {
        setModalHide1(false)
    }

    const onClose2 = () => {
        setModalHide2(false)
    }

    const orgCreateValid = () => {
        let checked = true;

        if (orgName === "") {
            checked = false;
            setOrgNameErrMsg1("enter orgname")
        } else {
            setOrgNameErrMsg1("")
        }
        if (orgAddress === "") {
            checked = false;
            setorgAddressErrMsg1("enter orgaddress")
        } else {
            setorgAddressErrMsg1("")
        }
        if (orgType === "") {
            checked = false;
            setorgTypeErrMsg1("enter orgtype")
        } else {
            setorgTypeErrMsg1("")
        }
        // if (orgRegDate === "") {
        //     checked = false;
        //     setorgRegDateErrMsg("enter orgregdate")
        // } else {
        //     setorgRegDateErrMsg("")
        // }
        return checked;
    }

    const orgValid = () => {
        let checked = true;

        if (updateOrganization1?.org_nm === "") {
            checked = false;
            setOrgNameErrMsg1("enter orgname")
        } else {
            setOrgNameErrMsg1("")
        }
        if (updateOrganization1?.org_add === "") {
            checked = false;
            setorgAddressErrMsg1("enter orgaddress")
        } else {
            setorgAddressErrMsg1("")
        }
        if (updateOrganization1?.org_type === "") {
            checked = false;
            setorgTypeErrMsg1("enter orgtype")
        } else {
            setorgTypeErrMsg1("")
        }
        // if (orgRegDate === "") {
        //     checked = false;
        //     setorgRegDateErrMsg("enter orgregdate")
        // } else {
        //     setorgRegDateErrMsg("")
        // }
        return checked;
    }

    const createOrg = async () => {
        const data = {
            "org_nm": orgName,
            "org_add": orgAddress,
            "org_type": orgType,
            "org_regdate": currentDate
        }
        try {
            const res = await createOrganization(data)
            if (res?.status === 200) {
                toast.success("new organization created")
                getAllOrg()
                setModalHide(false)
            }

        } catch (err) {
            if (err?.response?.status === 400) {
                toast.error(err?.response?.data?.data)
            }
        }
    }

    const onOrgSubmit = () => {

        if (orgCreateValid()) {
            createOrg()

            setOrgName("")
            setOrgAddress("")
            setOrgType("")
            setOrgRegDate("")
        }
        setModalHide(true)

    }

    useEffect(() => {
        if (modalHide === false) {
            setOrgName("")
            setOrgAddress("")
            setOrgType("")
            setOrgRegDate("")
        }
    }, [modalHide])

    const handleAddStudentClick = () => {
        setModalHide(true)
    }

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'short', day: '2-digit' };
        return new Date(dateString).toLocaleDateString('en-GB', options);
    };

    const handleOrgEditClick = (orgData) => {
        setEditOrgId(orgData.org_id)
        setModalHide1(true)
        setUpdateOrganization1(orgData)
    }

    const updateInputChange = (e) => {
        setUpdateOrganization1({ ...updateOrganization1, [e.target.name]: e.target.value });
    };

    const updateOrg = async () => {
        const data = { ...updateOrganization1 }
        try {
            const res = await updateOrganization(EditOrgId, data)
            if (res?.status === 200) {
                getAllOrg()
                toast.success("updated successfully")
                setModalHide1(false)
            }
        } catch (error) {
            console.log("ss", error);
        }
    }

    const onEdit = async () => {
        if (orgValid()) {
            updateOrg()
            setModalHide1(false)
        }
    }

    const userValid = () => {
        let checked = true;

        if (userName === "") {
            checked = false;
            setUNameErrMsg("enter userName")
            console.log("checked1", checked);
        } else {
            setUNameErrMsg("")
        }
        if (unm === "") {
            checked = false;
            setUnmErrMsg("enter userName")
        } else {
            setUnmErrMsg("")
        }
        if (password === "") {
            checked = false;
            setPswErrMsg("enter password")
        } else {
            setPswErrMsg("")
        }
        if (address === "") {
            checked = false;
            setAdderrMsg("enter address")
        } else {
            setAdderrMsg("")
        }
        if (mobNumber === "") {
            checked = false;
            setMobErrMsg("enter mobile number")
        } else {
            setMobErrMsg("")
        }
        return checked;
    }

    const createUser = async () => {
        const data = {
            "utype_id": "ut_FtmOsyj86",
            "name": userName,
            "Unm": unm,
            "password": password,
            "Uaddress": address,
            'umob': mobNumber,
            'org_id': adminOrgId,
            'uorg_idcard': ""
        }
        try {
            const res = await newUserCreate(data)
            if (res?.status === 200) {
                getAllOrg()
                toast.success("new admin created")
                setModalHide2(false)
            }
        } catch (err) {
            if (err?.response?.status === 400) {
                toast.error(err?.response?.data?.data)
            }
        }
    }




    const onUserSubmit = () => {

        if (userValid()) {
            createUser()

            setUserName("")
            setUnm("")
            setPassword("")
            setAdderss("")
            setMobNumber("")
        }
        setModalHide2(true)

    }

    useEffect(() => {
        if (modalHide2 === false) {
            setUserName("")
            setUnm("")
            setPassword("")
            setAdderss("")
            setMobNumber("")
        }
    }, [modalHide2])

    const handleAddAdminClick = (id) => {
        setModalHide2(true)
        setAdminOrgId(id)
    }

    return (
        <div className='w-[100vw-60px] h-[100vh] bg-[var(--bg-color1)] overflow-y-scroll' >
            <div className="z-40 w-max h-max flex items-center p-2 gap-1">
                <div className="manikin-cont w-[40px] h-[40px]">
                    <img src={logo} alt="logo" className='bg-[var(--text-primary-color)] rounded-full' />
                </div>
                <div className="w-max text-xl font-normal text-[#787878]">
                    MedTrain AI
                </div>
            </div>

            <div className='px-4 pt-4'>
                <div className='flex justify-end gap-3 items-center' >
                    <div className='font-extrabold'>Create New Organization</div>
                    <div className='border-[0.1px] border-black flex justify-center items-center'
                        onClick={handleAddStudentClick}
                    >
                        <AddIcon />
                    </div>
                </div>
                <div className=' h-[100%] grid grid-cols-1 sm:grid-cols-2 grid 3xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 gap-y-10 py-4  place-items-center overflow-y-scroll' >
                    {orgData?.map((org, i) => {
                        return (
                            <div className='flex flex-col justify-between border-[0.1px] border-[#CCCCCC] w-[18rem] h-[23rem] p-2 bg-[var(--secondary-color)]'>
                                <div className='w-[100%] h-max flex flex-col gap-2' >
                                    <div className='pb-2'>
                                        <div className='font-semibold text-base'>{org?.org_nm}</div>
                                    </div>
                                    <div>
                                        <div className='font-semibold text-xs'>Org ID:</div>
                                        <div className='font-extrabold '>{org?.org_id}</div>
                                    </div>
                                    <div>
                                        <div className='font-semibold text-xs'>Org Admin:</div>
                                        <div className='font-extrabold '>{org?.allocation?.map((admin, i) => {
                                            return (
                                                <div key={i}>
                                                    {admin?.organizersuser?.Unm}
                                                </div>
                                            )
                                        })}</div>
                                    </div>
                                    <div>
                                        <div className='font-semibold text-xs'>Org Type:</div>
                                        <div className='font-extrabold '>
                                            {org?.org_type}
                                        </div>
                                    </div>
                                    <div>
                                        <div className='font-semibold text-xs'>Address</div>
                                        <div className='font-extrabold '>
                                            {org?.org_add}
                                        </div>
                                    </div>
                                </div>
                                <div className='w-[100%] flex justify-end items-center'>
                                    <div className='w-[100%] h-max flex justify-end items-center gap-1'>
                                        <div className='cursor-pointer' onClick={() => handleAddAdminClick(org?.org_id)}>
                                            <TooltipUi name="Add Org Admin" icons={<AddIcon />} width="max" placement="bottom" />
                                        </div>
                                        <div className='cursor-pointer' onClick={() => {
                                            navigate(`/organization/${org?.org_id}/manikin`);
                                        }}>
                                            <TooltipUi name="Details" icons={<RightArrow />} width="max" placement="bottom" />
                                        </div>

                                        <div className='cursor-pointer' onClick={() => handleOrgEditClick(org)}>
                                            <TooltipUi name="Edit Organization" icons={<IoSettings color='black' />} width="max" placement="bottom" />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>

            </div>

            <AssignTestModal heading={"Create Student"} handleClose={onClose} open={modalHide}>
                <div className='p-4 font-extrabold'>Create New Organization</div>
                <form className='flex w-[500px] h-max flex-col gap-4 px-4 py-4'>
                    <div className='flex flex-col gap-4'>
                        <div className='flex flex-col justify-start'>
                            <div className='flex flex-col justify-start gap-2'>
                                <label htmlFor="orgName" className='font-medium text-[0.8rem]'>Org Name</label>
                                <input type="text" id="orgName" placeholder='Enter Org Name' className='border-b-[0.1px] focus:outline-none font-medium px-2'
                                    onChange={(e) => setOrgName(e.target.value)}
                                    value={orgName}
                                    name="orgName"
                                />
                            </div>
                            <div className='text-[0.8rem] text-red-500'>{orgName === "" ? orgNameErrMsg1 : ""}</div>
                        </div>

                        <div className='flex flex-col justify-start'>
                            <div className='flex flex-col justify-start gap-2'>
                                <label htmlFor="orgType" className='font-medium text-[0.8rem]'>Org Type</label>
                                <input type="text" id="" placeholder='Enter Org Type' className='border-b-[0.1px] focus:outline-none font-medium px-2'
                                    onChange={(e) => setOrgType(e.target.value)}
                                    value={orgType}
                                    name="orgType"
                                />
                            </div>
                            <div className='text-[0.8rem] text-red-500'>{orgType === "" ? orgTypeErrMsg1 : ""}</div>
                        </div>

                        <div className='flex flex-col justify-start'>
                            <div className='flex flex-col justify-start gap-2'>
                                <label htmlFor="orgAddress" className='font-medium text-[0.8rem]'>Org Address</label>
                                <textarea id="" placeholder='Enter Org Address' className='border-[0.1px] focus:outline-none font-medium p-2'
                                    onChange={(e) => setOrgAddress(e.target.value)}
                                    value={orgAddress}
                                    name="orgAddress"
                                ></textarea>
                            </div>
                            <div className='text-[0.8rem] text-red-500'>{orgAddress === "" ? orgAddressErrMsg1 : ""}</div>
                        </div>

                        <div className='flex flex-col'>
                            <div className='flex flex-col justify-start gap-2'>
                                <label htmlFor="orgRegDate" className='font-medium text-[0.8rem]'>Org Register Date</label>
                                <input type="text" id="orgRegDate" className='border-b-[0.1px] focus:outline-none'
                                    // onChange={(e) => setOrgRegDate(e.target.value)}
                                    value={currentDate}
                                    name="orgRegDate"
                                />
                            </div>
                            <div className='text-[0.8rem] text-red-500'>{orgRegDate === "" ? orgRegDateErrMsg1 : ""}</div>
                        </div>

                    </div>
                    <div className='flex justify-end gap-4 pt-4'>
                        <div className='hover:cursor-pointer  font-medium' onClick={() => setModalHide(false)}>Cancel</div>
                        <div className='w-max py-[0.05rem] px-[0.5rem] bg-[var(--primary-color)] text-[#fff] hover:cursor-pointer font-medium' onClick={onOrgSubmit}>
                            Submit
                        </div>
                    </div>

                </form>
            </AssignTestModal>

            <AssignTestModal heading={"Edit Organization"} handleClose={onClose1} open={modalHide1}>
                <div className='p-4 font-extrabold'>Edit Organization</div>
                <form className='flex w-[500px] h-max flex-col gap-4 px-4 py-4'>
                    <div className='flex flex-col gap-4'>
                        <div className='flex flex-col justify-start'>
                            <div className='flex flex-col justify-start gap-2'>
                                <label htmlFor="orgName" className='font-medium text-[0.8rem]'>Org Name</label>
                                <input type="text" id="orgName" placeholder='Enter Org Name' className='border-b-[0.1px] focus:outline-none font-medium px-2'
                                    onChange={updateInputChange}
                                    value={updateOrganization1?.org_nm}
                                    name="org_nm"
                                />
                            </div>
                            <div className='text-[0.8rem] text-red-500'>{updateOrganization1?.org_nm === "" ? orgNameErrMsg1 : ""}</div>
                        </div>

                        <div className='flex flex-col justify-start'>
                            <div className='flex flex-col justify-start gap-2'>
                                <label htmlFor="orgType" className='font-medium text-[0.8rem]'>Org Type</label>
                                <input type="text" id="" placeholder='Enter Org Type' className='border-b-[0.1px] focus:outline-none font-medium px-2'
                                    onChange={updateInputChange}
                                    value={updateOrganization1?.org_type}
                                    name="org_type"
                                />
                            </div>
                            <div className='text-[0.8rem] text-red-500'>{updateOrganization1?.org_type === "" ? orgTypeErrMsg1 : ""}</div>
                        </div>

                        <div className='flex flex-col justify-start'>
                            <div className='flex flex-col justify-start gap-2'>
                                <label htmlFor="orgAddress" className='font-medium text-[0.8rem]'>Org Address</label>
                                <textarea id="" placeholder='Enter Org Address' className='border-[0.1px] focus:outline-none font-medium p-2'
                                    onChange={updateInputChange}
                                    value={updateOrganization1?.org_add}
                                    name="org_add"
                                ></textarea>
                            </div>
                            <div className='text-[0.8rem] text-red-500'>{updateOrganization1?.org_add === "" ? orgAddressErrMsg1 : ""}</div>
                        </div>

                        <div className='flex flex-col'>
                            <div className='flex flex-col justify-start gap-2'>
                                <label htmlFor="orgRegDate" className='font-medium text-[0.8rem]'>Org Register Date</label>
                                <input type="text" id="orgRegDate" disabled className='border-b-[0.1px] focus:outline-none disabled:cursor-not-allowed'
                                    onChange={updateInputChange}
                                    value={updateOrganization1?.org_regdate}
                                    name="orgRegDate"
                                />
                            </div>
                            <div className='text-[0.8rem] text-red-500'>{updateOrganization1?.org_regdate === "" ? orgRegDateErrMsg1 : ""}</div>
                        </div>

                    </div>
                    <div className='flex justify-end gap-4 pt-4'>
                        <div className='hover:cursor-pointer  font-medium' onClick={() => setModalHide1(false)}>Cancel</div>
                        <div className='w-max py-[0.05rem] px-[0.5rem] bg-[var(--primary-color)] text-[#fff] hover:cursor-pointer font-medium' onClick={onEdit}>
                            Save
                        </div>
                    </div>

                </form>
            </AssignTestModal>

            <AssignTestModal heading={"Create Organization Admin"} handleClose={onClose2} open={modalHide2}>
                <div className='p-4 font-extrabold'>Create Organization Admin</div>
                <form className='flex w-[500px] h-max flex-col gap-4 px-4 py-4'>
                    <div className='flex flex-col gap-4'>
                        <div className='flex flex-col justify-start'>
                            <div className='flex flex-col justify-start gap-2'>
                                <label htmlFor="userName" className='font-medium text-[0.8rem]'>Name</label>
                                <input type="text" id="" placeholder='Enter Name' className='border-b-[0.1px] focus:outline-none font-medium px-2'
                                    onChange={(e) => setUserName(e.target.value)}
                                    value={userName}
                                    name="userName"
                                />
                            </div>
                            <div className='text-[0.8rem] text-red-500'>{userName === "" ? uNameErrMsg : ""}</div>
                        </div>

                        <div className='flex flex-col justify-start'>
                            <div className='flex flex-col justify-start gap-2'>
                                <label htmlFor="unm" className='font-medium text-[0.8rem]'>UserName</label>
                                <input type="text" id="unm" placeholder='Enter UserName' className='border-b-[0.1px] focus:outline-none font-medium px-2'
                                    onChange={(e) => setUnm(e.target.value)}
                                    value={unm}
                                    name="unm"
                                />
                            </div>
                            <div className='text-[0.8rem] text-red-500'>{unm === "" ? unmErrMsg : ""}</div>
                        </div>

                        <div className='flex flex-col justify-start'>
                            <div className='flex flex-col justify-start gap-2'>
                                <label htmlFor="password" className='font-medium text-[0.8rem]'>Password</label>
                                <input type="text" id="" placeholder='Enter Password' className='border-b-[0.1px] focus:outline-none font-medium px-2'
                                    onChange={(e) => setPassword(e.target.value)}
                                    value={password}
                                    name="password"
                                />
                            </div>
                            <div className='text-[0.8rem] text-red-500'>{password === "" ? pswErrMsg : ""}</div>
                        </div>

                        <div className='flex flex-col justify-start'>
                            <div className='flex flex-col justify-start gap-2'>
                                <label htmlFor="address" className='font-medium text-[0.8rem]'>Address</label>
                                <textarea id="" placeholder='Enter Your Address' className='border-[0.1px] focus:outline-none font-medium p-2'
                                    onChange={(e) => setAdderss(e.target.value)}
                                    value={address}
                                    name="address"
                                ></textarea>
                            </div>
                            <div className='text-[0.8rem] text-red-500'>{address === "" ? addErrMsg : ""}</div>
                        </div>

                        <div className='flex flex-col justify-start'>
                            <div className='flex flex-col justify-start gap-2'>
                                <label htmlFor="mobNumber" className='font-medium text-[0.8rem]'>Mobile Number</label>
                                <input type="number" id="" placeholder='Enter Mobile number' className='border-b-[0.1px] focus:outline-none font-medium px-2'
                                    onChange={(e) => setMobNumber(e.target.value)}
                                    value={mobNumber}
                                    name="mobNumber"
                                />
                            </div>
                            <div className='text-[0.8rem] text-red-500'>{mobNumber === "" ? mobErrMsg : ""}</div>
                        </div>

                    </div>
                    <div className='flex justify-end gap-4 pt-4'>
                        <div className='hover:cursor-pointer  font-medium' onClick={() => setModalHide2(false)}>Cancel</div>
                        <div className='w-max py-[0.05rem] px-[0.5rem] bg-[var(--primary-color)] text-[#fff] hover:cursor-pointer font-medium' onClick={onUserSubmit}>
                            Submit
                        </div>
                    </div>

                </form>
            </AssignTestModal>


        </div>
    );
}

export default Organization;
