import React from 'react';

const Delete = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="32" viewBox="0 -960 960 960" width="32"><path d="M338.461-190.154q-22.277 0-37.907-15.63-15.631-15.631-15.631-37.908v-450.462h-47.385v-28.795h147.693v-37.667h190.154v37.539h147.692v28.923h-47.384v450.206q0 23.193-15.451 38.494-15.45 15.3-38.088 15.3H338.461Zm308.308-504H313.846v450.462q0 10.769 6.923 17.692t17.692 6.923h283.693q9.231 0 16.923-7.692 7.692-7.692 7.692-16.923v-450.462ZM419.384-290.346h28.924v-332.923h-28.924v332.923Zm92.924 0h28.923v-332.923h-28.923v332.923ZM313.846-694.154v475.077-475.077Z"/></svg>
  );
}

export default Delete;

