import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Close from "../icons/Close";

const style = {
  position: "absolute",
  top:0,
  right:60,
  bgcolor: "background.paper",
  boxShadow: 24,
  height:"100%",
  minWidth: "20%",
  outline:"none",
};

export default function AssignTestModal({
  handleClose,
  open,
  children,
  heading,
}) {
  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          {children}
        </Box>
      </Modal>
    </div>
  );
}

