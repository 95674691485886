import { Box, CircularProgress, Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import * as dataSets from "./sensorData";
import "./playAndLearn.scss";
import { BsCheck2Square } from "react-icons/bs";
import { AiOutlineCloseSquare } from "react-icons/ai";
import {
  getLogsBySessionId,
  getSessionsByManikinId,
  userSessionAll,
} from "../../api/mqtt.rest";
import { socket } from "../../config/socket";
import gsap from "gsap";
import { useRef } from "react";
import SOCKET_DATA_TABLE from "../../components/table-component/SocketDataTable";
import { LuPlayCircle } from "react-icons/lu";
import { FaRegCircleStop } from "react-icons/fa6";
import { MdOutlineReplay } from "react-icons/md";
import Delete from "../../components/icons/Delete";
import PlayButton from "../../components/icons/PlayButton";
import Stop from "../../components/icons/Stop";
import Replay from "../../components/icons/Replay";
import Event from "../../components/icons/Event";
import Sensor from "../../components/icons/Sensor";
import Close from "../../components/icons/Close";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ClinicalNotes from "../../components/icons/ClinicalNotes";
import { index } from "d3";
import DebriefingIcon from '../../components/icons/DebriefingIcon';
import TestKnowledge from '../../components/icons/TestKnowledge';
import AssignTestModal from '../../components/common/AssignTestModal';
import CustomModal from "../../components/common/CustomModal";
import TooltipUi from "../../components/common/TooltipUi";
import More from "../../components/icons/More";
import SendIcon from "../../components/icons/SendIcon";
import TickIcon from "../../components/icons/TickIcon";
import { GrClose } from "react-icons/gr";
import { GrCheckmark } from "react-icons/gr";
import { useSearchParams } from 'react-router-dom'
import Speech from 'react-speech';
import EasySpeech from 'easy-speech'
import { CSVLink, CSVDownload } from "react-csv";
import { MdDownload } from "react-icons/md";
import { UpdateSessionName } from "../../api/mqtt.rest";
import { toast } from "react-toastify";
import { SOCKET_DATA_HEADERS_CSV } from "../../constant";


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className="pnl-tab-panel"
      {...other}
    >
      {value === index && <div className="tab-panel-div py-2">{children}</div>}
    </div>
  );
}

const PlayLearnTabsComponent = forwardRef((props, ref) => {
  const [value, setValue] = useState(0);
  const [sessionDataList, setSessionDataList] = useState([]);
  const [sessionData, setSessionData] = useState({});
  const [selectedSensorData, setSelectedSensorData] = useState([]);
  const [isSessionDataLoading, setIsSessionDataLoading] = useState(false);
  // const [isRunning, setIsRunning] = useState(false);
  const [activityList, setActivityList] = useState([]);
  const [selectOption, setSelectOption] = useState("Option 1")
  const [sessionId, setSessionId] = useState("")
  const [switch1, setSwitch1] = useState(false)
  // const [animate,setAnimate]=useState()
  const {
    selectedManikin,
    simulateSession,
    macId,
    init,
    timer,
    hideBottomSection,
    stopAnimation,
    isRunning,
    activeTabIndex,
    activeTabIndexSelection,
    onTabClick,
    sessionIdData,
    getSelectedSessionId,
    SwapBtn,
    time,
    listingMessage,
    uSessionfilter,
    seekerListMsg,
    isOnline,
  } = props;
  const initialTiltPos = 0;
  const sensorDataRef = useRef(null);
  // const [elapsedTime, setElapsedTime] = useState(0);
  // const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [modalHide, setModalHide] = useState(false)
  const [replaySessionId, setReplaySessionId] = useState("")
  const [modalHide1, setModalHide1] = useState(false)

  const [params, setParams] = useSearchParams()

  const qSessionId = params.get('sId')
  const qMId = params.get('mId')
  const uId = params.get("uId")
  const orgId = localStorage.getItem("orgId")
  const [sensorData, setSensorData] = useState([])
  const [sensorMessages, setSensorMessages] = useState([])
  const [bottomSecHide, setBottomSecHide] = useState(true)
  const sessionArray = []
  const [scrollLeftVisible, setScrollLeftVisible] = useState(false);
  const [scrollRightVisible, setScrollRightVisible] = useState(true);
  const tabsContainerRef = useRef(null);
  const [sessionNumber, setSessionNumber] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [results, setResults] = useState({});
  const [replayRunning, setReplayRunning] = useState(false);
  const [btnHide, setBtnHide] = useState(false)
  const [editIndex, setEditIndex] = useState(null);
  const [sessionName, setSessioName] = useState("")
  const userType = localStorage.getItem("userType")
  const [manikinData, setManikinData] = useState([]);
  const [manikinId, setManikinId] = useState("")
  const messages = []
  const debriefingCount = useRef(0);
  const debriefingTimer = useRef(null)

  
  
  const handleExpandClick = () => {
    setModalHide1(true)
  }

  useEffect(() => {
    setSensorMessages(...listingMessage)
  }, [])

  useEffect(() => {
    setReplayRunning(SwapBtn)
  }, [SwapBtn])

  useEffect(() => {
    init(initialTiltPos);
    // getAllManikins();
  }, []);

  useEffect(() => {
    const sensorDataContainer = sensorDataRef?.current;
    const lastItem = sensorDataContainer?.lastElementChild;
    lastItem?.scrollIntoView({ behavior: "smooth" });
  }, [selectedSensorData, value]);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  useImperativeHandle(ref, () => ({
    async refreshPage ()  {
      await userSessionAllDetails()
    },
    onStopReplay ()  {
      btnReplay()
    }
  }));
  const userSessionAllDetails = async () => {
    console.log("userSessionAllDetails");
    try {
      const pageNumber = 1
      const pageSize = 100
      const data = {
        "userId": uId,
        "filter": uSessionfilter
      }
      
        const res = await userSessionAll(pageNumber, pageSize, data)
        // setUSessionDetails(res?.data?.data?.data)
      console.log(res?.data?.data?.data)
        setSessionListData(res?.data?.data?.data)
    } catch (error) {
      console.log("error", error);
    }
  }

  const setSessionListData = (resData) => {
    console.log(resData)
    setSessionDataList(resData);
    resData?.forEach((d) => {
      if (d?.status === "running") {
        sessionIdData(d)
      }
    }
    )

    if (resData.length > 0 && (qSessionId == null || qMId !== selectedManikin) && (qMId == null || qMId !== selectedManikin)) {
      handleSessionSelection(resData[0], 0, resData.length)
    } else {
      resData.forEach((s, index) => {
        if (s?.session_id === qSessionId) {
          handleSessionSelection(resData[index], index, resData.length)
        }
      })
      setTimeout(() => {
        document.getElementById(`#tab_${qSessionId}`)?.scrollIntoView({
          behavior: 'smooth'
        });
      }, 100)
    }

    setSessionNumber(resData?.length);
  }

  useEffect(() => {
    if (!isRunning) {
      if (uId && userType === "instructor" && uSessionfilter !== '') {
        userSessionAllDetails()
      }
    }
  }, [uSessionfilter])

  useEffect(() => {
    if (!isRunning && userType === "user") {

      setIsSessionDataLoading(true);
      getSessionsByManikinId(selectedManikin)
        .then((resp) => {
          const resData = resp?.data?.data?.data
          setSessionListData(resData)
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setIsSessionDataLoading(false);
        });
    }
  }, [selectedManikin, isRunning]);
  

  const handleSessionSelection = (sessionData, index, sessionNumber) => {
    console.log(sessionData);
    
    debriefingCount.current = 0
    debriefingTimer.current = null
    activeTabIndexSelection(index)
    setSessionNumber(sessionNumber);
    setReplaySessionId(sessionData?.session_id)
    getSelectedSessionId(sessionData?.session_id)
    getDataBySessionId(sessionData?.session_id,sessionData,index)
  };

  const getDataBySessionId=(sessionId,sessionData,index)=>{
    getLogsBySessionId(sessionId)
      .then((resp) => {
        const sensorDataList = resp?.data?.data?.data?.map(
          (e) =>
            e?.sensor_value
        );
        setSensorData(sensorDataList)
      

        const manId = resp?.data?.data?.data[0]?.mnk_id
        if (sessionId) {
          onTabClick(sensorDataList, manId ,sessionDataList[index])
        }
        console.log(resp?.data?.data)
        setSessionData(resp?.data?.data)
        setSelectedSensorData(sensorDataList);
        
        if (sessionId) {
          breifingData(resp?.data?.data)
        }          
      })
      .catch((error) => {
        console.error(error);
      })
  }

  const breifingData =(sData)=>{
    console.log(sData)
    if (
      debriefingCount.current < 3 &&
      (sData?.sessionDebriefing?.debriefing?.length < 100)
    ) {
      
      debriefingTimer.current =  setTimeout(() => {
        debriefingCount.current = debriefingCount.current + 1;
        getDataBySessionId(sData?.sessionDebriefing?.session_id);
      }, 5000);
    }else{
      debriefingCount.current = 0
      debriefingTimer.current = null
    }
  }

  useEffect(() => {
    timer(isRunning);
    if (isRunning && replayRunning) {
      setReplayRunning(false)
    }
    if (isRunning) {
      setSessionData({})
    }

  }, [isRunning]);

  const btnReplay = () => {
    // console.log("klllllllll",sensorData);
    if (isOnline) {
      simulateSession(sensorData);  
      setReplayRunning(!replayRunning)
        if (time === false) {
          setSwitch1(!switch1)
        }
    }
    if (!isOnline) {
      setSwitch1(false)
    }
    
  };

  const StopReplay = () => {
    stopAnimation()
  }

  const handleDeleteClick = () => {
    setBottomSecHide(!bottomSecHide);
    hideBottomSection(bottomSecHide);
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 100,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
  };

  const [sessionHide, setSessionHide] = useState(true);
  const [sensorHide, setSensorHide] = useState(false);

  const handleSessionClick = () => {
    setSessionHide(true);
    setSensorHide(false);
    setValue(0);
  };

  const handleSensorClick = () => {
    setSensorHide(true);
    setSessionHide(false);
    setValue(1);
  };

  const handleScroll = () => {
    const container = tabsContainerRef.current;

    if (container) {
      setScrollLeftVisible(container.scrollLeft > 0);
      setScrollRightVisible(
        container.scrollLeft < container.scrollWidth - container.clientWidth
      );
    }
  };

  const handleScrollLeft = () => {
    const container = tabsContainerRef.current;
    if (container) {
      container.scrollBy({
        left: -container.clientWidth,
        behavior: "smooth",
      });
    }
  };

  const handleScrollRight = () => {
    const container = tabsContainerRef.current;
    if (container) {
      container.scrollBy({
        left: container.clientWidth,
        behavior: "smooth",
      });
    }
  };

  const handleSessionDeleteClick = (indexes) => {
    // const newArray = tabs.filter((_, index) => index !== indexes);
    // setTabs(newArray);
    // const newArray = sessionDataList
    let sessionId = sessionDataList[indexes].session_id
    const newArray = sessionDataList.filter((item) => {
      return item.session_id !== sessionId 
    })
    setSessionDataList(newArray)
  };

  const handleTestKnowledgeClick = () => {
    setModalHide(true)
  }
  const onClose = () => {
    setModalHide(false)
  }

  const onClose1 = () => {
    setModalHide1(false)
  }

  const handleOptionClick = (questionId, optionId) => {
    const isCorrect = checkAnswer(questionId, optionId);
    setSelectedOptions(prevState => ({
      ...prevState,
      [questionId]: { optionId, isCorrect }
    }));
  };

  const isOptionSelected = (questionId, optionId) => {

    return selectedOptions[questionId]?.optionId === optionId;
  };
  const checkAnswer = (questionId, optionId) => {
    const question = sessionData?.question.find(q => q.question_id === questionId);
    const correctOption = question?.options?.find(option => option.answer === true);
    return correctOption?.option_id === optionId;
  };

  const handleSubmit = () => {
    const newResults = {};
    for (const questionId in selectedOptions) {
      const { optionId, isCorrect } = selectedOptions[questionId];
      newResults[questionId] = { optionId, isCorrect };
    }
    setResults(newResults);
  };

  const getOptionName = (questionId) => {
    const optionId = selectedOptions[questionId]?.optionId;
    if (!optionId) return '';
    const question = sessionData?.question.find(q => q.question_id === questionId);
    const option = question.options.find(o => o.option_id === optionId);
    return option.choices;
  };

  const textToSpeech = async (action) => {

    try {
      const textWithoutHtml = sessionData?.sessionDebriefing?.debriefing?.replace(/<[^>]+>/g, '');
      setBtnHide(!btnHide);

      await EasySpeech.init();

      const voices = EasySpeech.voices();
      const voice = voices[2];

      const utterance = new SpeechSynthesisUtterance(textWithoutHtml);
      utterance.voice = voice;


      utterance.onerror = function (event) {
        console.error('Speech synthesis error:', event);
      };

      utterance.onend = function () {
        console.log('Speech synthesis complete.');
        setBtnHide(false);
      };

      if (action === 'play') {
        await EasySpeech.speak(utterance);
      } else {
        EasySpeech.cancel();
      }
    } catch (error) {
      console.error('An error occurred during speech synthesis:', error);
    }
  }

  const handleDoubleClick = (ind) => {
    setEditIndex(ind);
    setSessioName(sessionDataList[ind]?.session_name || sessionDataList[ind]?.session_id);
  };

  const handleEditChange = (value) => {
    setSessioName(value);
  };

  const handleEditSubmit = (ind, id, e) => {
    e.preventDefault()
    UpdateSession(id, ind)
    setEditIndex(null);
  };

  const UpdateSession = async (id, ind) => {
    const data = {
      "session_id": id,
      "session_name": sessionName
    }
    sessionDataList[ind].session_name = sessionName
    setSessionDataList(sessionDataList);
    try {
      const res = await UpdateSessionName(data)
      if (res?.status === 200) {
        toast.success("session name updated successfully ")
      }
    } catch (error) {
      console.log("er", error);
    }
  }

  // useEffect(() => {
  //   if (seekerListMsg) {
  //     const targetIndex = listingMessage?.findIndex(obj => obj.key === seekerListMsg.key);

  //     if (targetIndex !== -1) {
  //       console.log("kooooooooo", listingMessage?.slice(targetIndex, -1))
  //       // listingMessage.slice(targetIndex, -1).forEach((d) => {
  //       //   // console.log("dsfhdsh2111111111111111", d);
  //       //   messages.push(...messages, d)
  //       // })
  //     }
  //   } else {
  //     console.log("jhjhjhjjh");
  //   }
  // }, [seekerListMsg])

  // console.log("hdfgsd22222222222",messages);

  return (
    <>
      <div className="w-100 h-100 play-learn-tabs-container">
        <Box
          className="flex justify-between items-center w-[100%] overflow-x-scroll "
          sx={{ paddingRight: 1, paddingLeft: 1 }}
        >
          <div className="w-[1000px] overflow-x-scroll ">

            {/* {macId && ( */}
            <div className="tab-slider">
              {sessionDataList?.length > 0 && <div
                className={`tab-slider-arrow visible`}
                onClick={handleScrollLeft}
              >
                &lt;
              </div>}

              <div
                className="tab-slider-tabs-container"
                ref={tabsContainerRef}
                onScroll={handleScroll}
              >
                <div className="tab-slider-tabs">
                  {/* if the session is running */}
                  {isRunning && (
                    <div
                      className={`tab-slider-tab last-tab border-blue-500 border-b-2`}
                    >
                      <span>
                        SESSION
                        <span className="relative text-xs text-[var(--text-primary-color)] bottom-[2.3px] ">
                          LIVE{" "}
                          <div className="absolute bg-[var(--text-primary-color)] w-[7px] h-[7px] rounded-full bottom-1 right-[-0.5rem]"></div>
                        </span>
                      </span>
                    </div>
                  )}

                  {isSessionDataLoading ? (
                    <div className="flex flex-row w-100">
                      <CircularProgress />
                    </div>
                  ) : sessionDataList?.length > 0 ? (

                    sessionDataList?.map((session, ind) => {
                      const sessionNumber = sessionDataList?.length - ind;
                      sessionArray.push(sessionNumber)
                      return (
                        <div id={`#tab_${session?.session_id}`}
                          onClick={() =>
                            handleSessionSelection(
                              session,
                              ind,
                              sessionNumber
                            )
                          }
                          style={{ width: "max-content" }}
                          className={`tab-slider-tab  ${ind === activeTabIndex && !isRunning
                            ? " border-blue-500 border-b-2 text-black"
                            : "text-slate-400"
                            } ${(isRunning || replayRunning)
                              ? "cursor-not-allowed pointer-events-none"
                              : "cursor-pointer pointer-events-auto"
                            }`}
                          key={ind}
                        >
                          {
                            editIndex === ind ?
                              <form onSubmit={(e) => handleEditSubmit(ind, session?.session_id, e)}>
                                <input
                                  type="text"
                                  value={sessionName}
                                  className="w-[100%] focus:border-none focus:outline-none"
                                  autoFocus
                                  onChange={(e) => handleEditChange(e.target.value)}
                                />
                              </form>
                              :

                              <span className={`${session?.is_assessment ? 'text-[var(--primary-color)]' : "text-black"}`} 
                              onDoubleClick={() => handleDoubleClick(ind)}>
                                {session?.session_name ? session?.session_name : '' + session?.session_id}
                               {/* { isRunning && <span className="relative text-xs text-[var(--text-primary-color)] bottom-[2.3px] ">
                          LIVE{" "}
                          <div className="absolute bg-[var(--text-primary-color)] w-[7px] h-[7px] rounded-full bottom-1 right-[-0.5rem]"></div>
                        </span>} */}
                              </span>

                          }
                          {/* } */}
                          <div
                            className="close_icon"
                            onClick={() => handleSessionDeleteClick(ind)}
                          >
                            <Close color="black" />
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="h-max">
                      {/* Session data not available for this manikin */}
                    </div>
                  )}
                </div>
              </div>
              {
                sessionDataList?.length > 0 && <div
                  className={`tab-slider-arrow visible`}
                  onClick={handleScrollRight}
                >
                  &gt;
                </div>
              }

            </div>
            {/* // )}  */}
          </div>

          <div
            aria-label="play-and-learn-tabs"
            className="play-learn-tabs  flex "
          >
            <div className="flex gap-[1px] ">
              <span
                className={`${sensorHide ? "border-b-[0.1px] border-[#C00000]" : ""
                  } hover:cursor-pointer`}
                onClick={handleSensorClick}
              >
                <TooltipUi name="Sensor" icons={<Sensor color={sensorHide ? "#C00000" : "black"} />} width="max" placement="bottom" />
              </span>
              <span
                className={`${sessionHide ? "border-b-[0.1px] border-[#C00000]" : ""
                  } hover:cursor-pointer`}
                onClick={handleSessionClick}
              >

                <TooltipUi name="Session" icons={<Event color={sessionHide ? "#C00000" : "black"} />} width="max" placement="bottom" />

              </span>
            </div>

            <div className="flex gap-[1px]">
              <button
                className={` d-flex flex-column align-items-center justify-content-center running mb-0 `}
                onClick={btnReplay}
              >
                <span className="cursor-pointer">
                  {!switch1 ?
                    <TooltipUi name="Replay" icons={<Replay />} width="max" placement="bottom" /> :
                    <TooltipUi name="Stop" icons={<Stop />} width="max" placement="bottom" />}
                </span>
              </button>
              {/* <button onClick={StopReplay}>
                stop
              </button> */}
            </div>

            <div>
              <span
                className="hover:cursor-pointer"
                onClick={handleDeleteClick}
              >
                <TooltipUi name="Delete" icons={<Delete />} width="max" placement="bottom" />
              </span>
            </div>
          </div>
        </Box>

{/* {console.log("khjshjhsjsjs",listingMessage)} */}
        {sessionHide ? (
          <div className="h-[100%] pb-10">
            {sensorData && sessionDataList?.length > 0 ? (
              <div className="w-[100%] h-[100%] flex px-3">
                <div className="basis-8/12 h-[100%] overflow-y-scroll flex flex-col gap-[0.25rem]">
                  {/* {macId ? ( */}
                  <>
                    {isRunning
                      ? "Live Session"
                      : `Session Events of ${sessionData?.sessionDebriefing?.session_name || sessionData?.sessionDebriefing?.session_id}`}
                    <div className="h-[100%] pb-4">
                      {listingMessage?.length > 0 &&
                        listingMessage?.map((item, index) => {

                          return (
                            <div
                              className="flex gap-2 h-[2rem]  text-sm  "
                              key={item.key}
                            >
                              {" "}
                              <p
                                className={` ${item?.type === "error"
                                  ? "text-red-400"
                                  : item?.type === "warning"
                                    ? "text-orange-400"
                                    : "text-black"
                                  }`}
                              >
                                <div className="flex">
                                  <p className="text-black flex justify-center w-[2rem]">
                                    {listingMessage?.length - index}.
                                  </p><p>{item?.message}</p>
                                </div>
                              </p>
                              <p className=""> - {item?.date}</p>
                            </div>
                          );
                        })}
                    </div>
                  </>
                  {/* ) : (
                    ""
                  )} */}
                </div>

                <div className="basis-7/12 h-[100%] overflow-y-scroll">
                  <div className="w-[100%] h-max flex gap-20">
                    <p className='flex'><span><DebriefingIcon /></span> <span> Debriefing </span></p>

                    <div className='flex gap-4'><span className='cursor-pointer' onClick={handleTestKnowledgeClick}><TooltipUi name="Test Knowledge" icons={<TestKnowledge />} width="max" placement="bottom" /> </span><TooltipUi name="Note" icons={<ClinicalNotes />} width="max" placement="bottom" /><span className="rotate-180 relative bottom-2" onClick={handleExpandClick}><TooltipUi name="Expand" icons={<More color="#111" placement="bottom" />} width="max" /></span>
                      <span>
                        {!btnHide ?
                          (<button onClick={() => textToSpeech("play")}>
                            <TooltipUi name="Play Audio" icons={<PlayButton />} width="max" placement="bottom" />
                          </button>) :
                          (<button onClick={() => textToSpeech("stop")}>

                            <TooltipUi name="Stop Audio" icons={<Stop />} width="max" placement="bottom" />
                          </button>)
                        }
                      </span>
                      <CSVLink data={selectedSensorData} headers={SOCKET_DATA_HEADERS_CSV} filename={`${sessionData?.sessionDebriefing?.session_name || sessionData?.sessionDebriefing?.session_id}.csv`}>
                        <TooltipUi name="Download Sensor Data" icons={<MdDownload color="black" className="w-[20px] h-[20px]" />} width="max" placement="bottom" />
                      </CSVLink>
                    </div>
                  </div>

                  <div className="pt-0  px-2 border-l-[0.1px] border-gray-200 text-xs ">
                    {sessionData?.sessionDebriefing?.debriefing ? (
                      <>
                        <div dangerouslySetInnerHTML={{ __html: sessionData?.sessionDebriefing?.debriefing }}>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

              </div>
            ) : (
              <></>
            )}
          </div>
        ) : (
          ""
        )}

        {sensorHide ? (
          <CustomTabPanel value={value} index={1} name="Sensor">
            <div
              ref={sensorDataRef}
              className="flex flex-column justify-content-center sensor-data-list text-black"
            >
              {macId && sessionDataList?.length > 0 && (
                <>
                  {" "}
                  Sensor Events for {sessionData?.sessionDebriefing?.session_name || sessionData?.sessionDebriefing?.session_id}
                  {!!selectedSensorData && selectedSensorData?.length > 0 ? (
                    <div className="">
                      <SOCKET_DATA_TABLE body={selectedSensorData} />
                    </div>
                  ) : (
                    <div className="d-flex flex-row justify-content-center">
                      Sensor data not available
                    </div>
                  )}
                </>
              )}
            </div>
          </CustomTabPanel>
        ) : (
          " "
        )}
      </div>

      <AssignTestModal heading={"Assign a Test"} handleClose={onClose} open={modalHide}>
        <div className="py-4 px-4 w-max h-max overflow-y-scroll">
          <div>
            <div className="flex gap-1.5 items-center">
              <div>
                <TestKnowledge />
              </div>
              <div className="text-[1.2rem] font-medium">Test your Knowledge</div>
            </div>
          </div>

          <div className="py-4 flex flex-col gap-y-6">
            {sessionData?.question?.map(question => (

              <div key={question.question_id} className="overflow-y-scroll">
                <div className="w-[25rem]">{question.question}</div>

                <div className="flex justify-start gap-2">
                  {question?.options?.map(option => (
                    <div key={option?.option_id}>
                      <div className={`w-max px-4 py-1.5 border-gray-300 border-[0.1px] cursor-pointer ${isOptionSelected(question.question_id, option.option_id) ? 'bg-[#FFF2CC]' : ''} `} onClick={() => handleOptionClick(question.question_id, option.option_id)}>{option.choices}</div>
                    </div>
                  ))}
                </div>
                <div className="w-[100%] flex justify-end py-2 gap-2">
                  {results[question.question_id] && (
                    <div className={`w-max`}>
                      {results[question.question_id].isCorrect ? <div className="px-2.5 py-1 rounded-full bg-green-600"><GrCheckmark className="text-white" /></div> : <div className="px-2.5 rounded-full py-1 bg-red-400"><GrClose className="text-white" /></div>}
                    </div>
                  )}
                  {selectedOptions[question.question_id] && (
                    <p className="selectedOptionText">
                      {getOptionName(question.question_id)}
                    </p>
                  )}

                </div>

              </div>
            ))}
          </div>
          <div className="w-100  flex justify-end items-center">
            <button className=" flex items-center px-2 bg-[var(--primary-color)]" onClick={handleSubmit}><span><SendIcon color="#fff" /></span><span className="text-white">Show Result</span></button>
          </div>
        </div>
      </AssignTestModal>

      <AssignTestModal heading={""} handleClose={onClose1} open={modalHide1}>
        <div className="basis-5/12 h-[100vh] ">
          <div className="w-[100%] h-max flex gap-20 pt-3 px-2 overflow-y-scroll">
            <p className='flex'><span><DebriefingIcon /></span> <span> Debriefing </span></p>

            <div className='flex gap-4'><span className='cursor-pointer' onClick={handleTestKnowledgeClick}><TooltipUi name="Test Knowledge" icons={<TestKnowledge />} width="max" /> </span><TooltipUi name="Note" icons={<ClinicalNotes />} width="max" /><span onClick={() => setModalHide1(false)}><TooltipUi name="Restore" icons={<More />} width="max" /></span></div>
          </div>

          <div className="pt-0 pb-12 w-[417px] h-[100vh] px-2 border-l-[0.1px] border-gray-200 text-xs overflow-y-scroll">
            {sessionData?.sessionDebriefing?.debriefing ? (
              <div dangerouslySetInnerHTML={{ __html: sessionData?.sessionDebriefing?.debriefing }}>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </AssignTestModal>
    </>
  );
});

export default PlayLearnTabsComponent;
