import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { login, profile } from '../../api/user.rest';
import styles from "../../components/common/sysadminlogin.module.scss"
import { GoEye } from "react-icons/go";
import { GoEyeClosed } from "react-icons/go";
import axios from "axios";
import { toast } from "react-toastify";

function SysAdminLogin() {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        userName: '',
        password: '',
        isSubmited: false,
    })
    const [showPassword,setShowpassword]=useState(false)
    const [randomNumber, setRandomNumber] = useState(559922);
     
    const generateRandomNumber=()=>{
        const newRandomNum=Math.floor(Math.random()*900000)+100000

        setRandomNumber(newRandomNum)
    }

    const [errorMesg,SetErrorMesg]=useState("")
    const [pswErrMesg,setPswErrMesg]=useState("")
    const [status,setStatus]=useState('')



    const onReset=()=>{
        setFormData({
            userName: '',
            password:''
        });
    }
    /*
    * Input 
    * Event Handller
    */
    const inputHandler = (eve, type) => {
        const txt = eve.target.value;
        if (type === 'name') {
            setFormData({
                ...formData,
                userName: txt
            });
        }
        if (type === 'password') {
            setFormData({
                ...formData,
                password: txt
            });
        }
    }

    /*
    * Input 
    * Event Handller
    */
    const valid = () => {
        let checked = true;
        const { userName, password } = formData;
        if (userName === "") {
            checked = false;
            SetErrorMesg("Enter UID")
        }
        if (password === "") {
            checked = false;
            setPswErrMesg("Enter Password")
        }

        return checked;
    }



    /*
    * API 
    * Login
    */
    const apiLogin = async () => {
        const { userName, password } = formData;
        const data = {
            "username": userName,
            "password": password
        };
        try {

            // const res = await login('root' || 'admin' || 'user', data);

            const res = await login('root', data);
            console.log(res)
            const token = res.data?.data?.token;
            const orgId=res.data?.data?.userDetails?.org_id
            localStorage.setItem('token', token);
            localStorage.setItem('orgId', orgId);
            myProfile()
            if (res?.status===200) {
                localStorage.setItem("userNm",userName)
                toast.success("login successful")
            }
        } catch (ex) {
            // console.log("sererre",ex.response.status);
            if (ex?.response?.status===400) {
                toast.error("invalid credentials")
            }
            if (ex?.response?.data?.status === 'fail') {
                try {
                    const res = await login('admin', data);
                    const token = res.data.data.token;
                    localStorage.setItem('token', token);
                    myProfile();

                } catch (ex) {
                    setStatus(ex?.response?.data?.status)
                    SetErrorMesg(ex.response.data.data)
                    setPswErrMesg(ex.response.data.data)
                    // if(ex.response.data.status === 'fail'){
                    if (ex?.response?.data?.status === 'fail') {
                        try {
                            // const res = await login('root' || 'admin' || 'user', data);

                            const res = await login('user', data);
                            const token = res.data.data.token;
                            localStorage.setItem('token', token);
                            myProfile();

                        } catch (ex) {
                            console.log(ex);
                            // if(ex.response.data.status === 'fail'){

                            // }
                        }
                    }
                    // }
                }
            }
        }
    }
    /*
    * API 
    * My Profile
    */
    const myProfile = async () => {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('token')
        }

        axios.get(`${process.env?.REACT_APP_BASE_URL}/me`, {
            headers: headers
        })
            .then((response) => {
                console.log(response.data.data.role.utype_name)
                localStorage.setItem('userType', response.data.data.role.utype_name);
                if (response.data.data.role.utype_name === 'root') {
                    navigate("/manikin");
                }
                if (response.data.data.role.utype_name === 'user') {
                    navigate("/playAndLearn");
                }
                if (response.data.data.role.utype_name === 'instructor') {
                    navigate("/student");
                }
                if (response.data.data.role.utype_name === 'admin') {
                    navigate("/instructor");
                }
                // navigate("/dashboard");
                window.location.reload();
            })
            .catch((error) => {
                console.log(error);
            })
    }

    /*
    * Form
    * Submitted
    */
    const submitted = (e) => {
        e.preventDefault()
        setFormData({
            ...formData,
            isSubmited: true
        });
        if (valid()) {
            apiLogin();
        }

        if (formData.userName==="") {
            SetErrorMesg("Enter UID")
        }else if(formData.password===""){
            setPswErrMesg("Enter Password")
        }
    }


    const handleShowpassword=()=>[
        setShowpassword(!showPassword)
    ]

    return (
        // <div className={styles.sysAdmin_cont} style={{background:"red"}} >
        //     {/* <h5 className='form-header'>Login</h5> */}
        //     {/* <form> */}

        //     <div className={styles.inp_Container}>
        //         <input type="text" className={styles.inputfield}/>
        //     </div>

        //     {/* <input type="text"
        //         style={formData.isSubmited && formData.userName === '' ? { border: "1px solid red", borderRadius: "4px" } : {}}
        //         onChange={(e) => inputHandler(e, 'name')}
        //         name='userName'
        //         className="login-input"
        //         placeholder='Username' />
        //     <input type="password"
        //         style={formData.isSubmited && formData.password === '' ? { border: "1px solid red", borderRadius: "4px" } : {}}
        //         onChange={(e) => inputHandler(e, 'password')}
        //         name='password'
        //         className="login-input mb-0"
        //         placeholder='Password' /> */}
        //     {/* <div className="text-end m-b-15"><Link to="#" className='fs-12 c-primary'>Forget Password</Link></div>
        //     <button className="btn-submit m-b-25" onClick={submitted}>Login</button> */}
        //     {/* </form> */}  
        // </div>
        <form className={styles.sysAdmin_cont} >
            <div className={styles.inp_maincont}>
            <div style={formData.isSubmited && formData.userName === '' && status === "fail" ? { borderBottom:'0.1px solid red'} : {}}  className={styles.inp_cont}>
                <div style={{'width': "fit-content", whiteSpace: "nowrap"}}>User ID</div>
                <input  type="text" className={styles.inputfield} onChange={(e) => inputHandler(e, 'name')} value={formData.userName}
                name='userName'/>
            </div>
            <div className={styles.suggestion}>{formData.isSubmited ?errorMesg:""}</div>
            </div>
            <div className={styles.inp_maincont}>
            <div style={formData.isSubmited && formData.password === '' && status === "fail" ? { borderBottom:'0.1px solid red'} : {}} className={`${styles.inp_cont} `}> 
            <div>Password</div>
                <input type={showPassword?"text":"password"} className={`${styles.pswInp}  ${styles.inputfield}`} onChange={(e) => inputHandler(e, 'password')} value={formData.password} name='password'/>
                <div className={styles.eyeicon} onClick={handleShowpassword}>
               {showPassword?<GoEyeClosed />:<GoEye />}
                </div>
            </div>
            <div className={styles.suggestion}>{formData.isSubmited ?pswErrMesg:""}</div>
            </div>
            {/* <div className={styles.otp_cont}>
                <div className={styles.otp_inp_cont}>
                    <div className={styles.otp}>{randomNumber}</div>
                    <div className={styles.input}>
                        <input type="text" name="" id="" className={styles.otp_input} placeholder='Type the Code'/>
                    </div>
                </div>
                <div className={styles.regenerate_link} onClick={generateRandomNumber}>
                    Re-generate
                </div>
            </div> */}
            <div className={styles.btn_container}>
                {/* <div className={styles.reset_btn} onClick={onReset}>Reset</div> */}
                <button className={styles.next_btn} onClick={submitted} type='submit'>Next</button>
            </div>
        </form>
    )
}

export default SysAdminLogin;   